import { useEffect, useState } from 'react'
import WorkspacesAndBoards from '../../components/WorkspacesAndBoards/WorkspacesAndBoards'
import {
  getAccessToken,
  getMigratedFoldersFromDatabase,
  getValueFromSecureStorage,
} from '../../util/backend.service'
import {
  getAccountName,
  getFolderWorkspace,
  getWorkspaces,
  saveValueIntoMondayStorage,
} from '../../util/monday.service'
import Spinner from '../../components/Common/Spinner/Spinner'
import './MainScreen.css'
import { Button, Dropdown } from 'monday-ui-react-core'
import DatabaseSync from '../../components/DatabaseSync/DatabaseSync'
import { MigratedFolders } from '../../components/MigratedFolders/MigratedFolders'

const MainScreen = ({ monday, context }) => {
  const [authorized, setAuthorized] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [workspaces, setWorkspaces] = useState([])
  const [selectedWorkspace, setSelectedWorkspace] = useState(null)
  const [isFormValid, setIsFormValid] = useState(false)
  const [isDev, setIsDev] = useState(null) // To store the output of checkIfDev
  const [migratedFolders, setMigratedFolders] = useState([])

  const checkIfDev = async () => {
    const res = await getAccountName(monday)
    const devStatus = res === 'CapabilitySource'
    console.log(`Setting dev ${devStatus ? 'true' : 'false'}`, res, context)
    setIsDev(devStatus) // Set the dev status to state
  }

  useEffect(() => {
    checkIfDev()
  })

  useEffect(() => {
    if (isDev !== null && monday && context) {
      const initialize = async () => {
        setIsLoading(true)
        const sessionTokenData = await monday.get('sessionToken')
        const value = await getValueFromSecureStorage(
          sessionTokenData?.data,
          isDev
        )
        setAuthorized(value)
        console.log(value, 'authorized value')
        setIsLoading(false)
      }

      const getOauthCode = async () => {
        const urlParams = new URLSearchParams(window.location.search)
        if (urlParams.get('code')) {
          const code = urlParams.get('code')
          await getAccessToken(
            code,
            saveValueIntoMondayStorage,
            setAuthorized,
            monday,
            isDev
          )
        }
      }

      if (window.location.search.includes('code=')) {
        getOauthCode()
      } else {
        initialize()
      }
    }
  }, [monday, context, isDev])

  const handleFormValid = (valid) => {
    setIsFormValid(valid)
  }

  useEffect(() => {
    const fetchWorkspaces = async () => {
      setIsLoading(true)
      const resp = await getWorkspaces(monday)
      const folders = await getMigratedFoldersFromDatabase(monday, isDev)
      if (folders.length) {
        const folderIds = folders.map((folder) => folder.folderId)
        const workspaces = await getFolderWorkspace(monday, folderIds)
        const foldersWithWorkspace = folders.map((folder) => {
          const workspaceFound = workspaces.find(
            (val) => val.folderId === folder.folderId
          )
          return {
            folderName: folder?.folderName || '',
            workspaceName: workspaceFound?.workspace?.name || '',
          }
        })
        setMigratedFolders(foldersWithWorkspace)
      }
      if (resp) {
        setWorkspaces(resp)
      }
      setIsLoading(false)
    }

    if (authorized && monday && isDev !== null) {
      fetchWorkspaces()
    }
  }, [authorized, monday, isDev])

  if (isLoading) {
    return (
      <div className="mainScreenBody">
        <div
          style={{
            width: '100%',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner context={context} />
        </div>
      </div>
    )
  }

  if (!authorized) {
    return (
      <div>
        <h3>Authorize with monday.com</h3>
        <Button
          onClick={() => {
            window.open(
              `https://auth.monday.com/oauth2/authorize?client_id=${process.env.REACT_APP_MONDAY_CLIENT_ID}`,
              '_self'
            )
          }}
        >
          Authorize
        </Button>
      </div>
    )
  }

  return (
    <div className="mainScreenBody">
      <div
        style={{
          width: '100%',
          height: '100%',
          margin: 15,
          marginBottom: 30,
        }}
      >
        <h1 style={{ margin: 'auto' }}>Please select a workspace</h1>
        <div
          className="dropdown"
          style={{ width: '300px', margin: 'auto', marginTop: '2rem' }}
        >
          <Dropdown
            className={
              context?.data?.theme !== 'light'
                ? 'dropdown_comp'
                : 'dropdown_comp_white'
            }
            dropdownMenuWrapperClassName={
              context?.data?.theme !== 'light'
                ? 'dropdown_comp'
                : 'dropdown_comp_white'
            }
            onClear={() => {
              setSelectedWorkspace(null)
            }}
            onOptionSelect={(value) => {
              setSelectedWorkspace(value)
            }}
            isLoading={isLoading}
            options={workspaces.map((workspace) => {
              return { label: workspace.name, value: workspace.id }
            })}
            placeholder={'Select workspace'}
            searchable={true}
            noOptionsMessage={() => 'No workspaces'}
            loadingMessage={() => 'Fetching workspaces...'}
            size={'medium'}
          />
        </div>
        {authorized && selectedWorkspace && isDev !== null && (
          <WorkspacesAndBoards
            selectedWorkspace={selectedWorkspace}
            context={context}
            monday={monday}
            setSelectedWorkspace={setSelectedWorkspace}
            setAppLoading={setIsLoading}
            isFormValid={isFormValid}
            isDev={isDev} // Pass the determined dev status here
          />
        )}
        {isDev !== null && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              gap: '2rem',
              marginTop: '2rem',
            }}
          >
            {authorized && migratedFolders.length !== 0 && (
              <MigratedFolders
                folders={migratedFolders}
                context={context}
              ></MigratedFolders>
            )}
            <DatabaseSync handleFormValid={handleFormValid} isDev={isDev} />
          </div>
        )}
      </div>
    </div>
  )
}

export default MainScreen
