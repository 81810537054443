import CryptoJS from 'crypto-js'
import { getBackendUrl } from './util'

export const getAccessToken = async (
  code,
  saveValueIntoMondayStorage,
  setAuthorized,
  monday,
  isDev
) => {
  const sessionTokenData = await monday.get('sessionToken')
  const settings = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: sessionTokenData.data,
      appRequest: true,
    },
    body: JSON.stringify({ code: code }),
  }
  try {
    const url = getBackendUrl(isDev)
    const resp = await fetch(`${url}/oauth`, settings)
    if (resp.status === 200) {
      await saveValueIntoMondayStorage('authorized', true, monday)
      setAuthorized(true)
    }
  } catch {
    console.log('Error sending oauth code from getAccessToken oauth')
  }
}

export const getValueFromSecureStorage = async (sessionToken, isDev) => {
  const settings = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: sessionToken,
      appRequest: true,
    },
  }
  try {
    const url = getBackendUrl(isDev)
    const resp = await fetch(`${url}/exporter/checkAuthorizedUsers`, settings)
    if (resp.status === 200) {
      return await resp.json()
    }
  } catch (error) {
    console.log('Error sending oauth code from secure storage', error)
  }
}

export const exportToDatabase = async (folderIds, boardIds, monday, isDev) => {
  try {
    const sessionTokenData = await monday.get('sessionToken')
    const body = JSON.stringify({
      folderIds: folderIds,
      boardIds: boardIds,
    })
    const settings = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: sessionTokenData.data,
        appRequest: true,
      },
      body: body,
    }
    const url = getBackendUrl(isDev)
    await fetch(`${url}/exporter/exportData`, settings)
  } catch (error) {
    return error
  }
}

export const getMigratedBoardsFromDatabase = async (monday, isDev) => {
  const sessionTokenData = await monday.get('sessionToken')
  try {
    const settings = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: sessionTokenData.data,
        appRequest: true,
      },
    }
    const url = getBackendUrl(isDev)
    const resp = await fetch(`${url}/boards/getExportedBoards`, settings)
    if (resp.status !== 200) {
      console.log(resp.status)
      return []
    }
    const data = await resp.json()
    if (!data) {
      console.log('Missing data from response json')
      return []
    }
    return data?.cleanIds
  } catch (error) {
    console.log(error, 'Error while fetching migrated boards')
    return []
  }
}

export const getMigratedFoldersFromDatabase = async (monday, isDev) => {
  const sessionTokenData = await monday.get('sessionToken')
  try {
    const settings = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: sessionTokenData.data,
        appRequest: true,
      },
    }
    const url = getBackendUrl(isDev)
    const resp = await fetch(`${url}/boards/getExportedFolders`, settings)
    if (resp.status !== 200) {
      console.log(resp.status)
      return []
    }
    const data = await resp.json()
    if (!data) {
      console.log('Missing data from response json')
      return []
    }
    return data.folderNames
  } catch (error) {
    console.log(error, 'Error while fetching migrated boards')
    return []
  }
}

export const testDBConnection = async (monday, dbType, credentials, isDev) => {
  try {
    let bodyValue
    const encryptedCredentialsString = CryptoJS.AES.encrypt(
      JSON.stringify(credentials),
      process.env.REACT_APP_MONDAY_SIGNING_SECRET
    ).toString()
    const sessionToken = await monday.get('sessionToken')
    if (dbType === 'PG') {
      bodyValue = JSON.stringify({ pgCredentials: encryptedCredentialsString })
    } else {
      throw Error
    }
    const url = getBackendUrl(isDev)
    const response = await fetch(`${url}/testConnection`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: sessionToken?.data,
        appRequest: true,
      },
      body: bodyValue,
    })
    return await response.json()
  } catch (error) {
    console.log(error, 'error')
    return false
  }
}

export const saveToSecureStorage = async (monday, isDev, body) => {
  try {
    const sessionToken = await monday.get('sessionToken')
    const url = getBackendUrl(isDev)
    const response = await fetch(`${url}/exporter/saveToSecureStorage`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: sessionToken?.data,
        appRequest: true,
      },
      body: JSON.stringify(body),
    })
    return await response.json()
  } catch (error) {
    console.log(error, 'error')
    return false
  }
}
