export const sortData = (selectedData, originalData) => {
  const sortedData = [...originalData]
  sortedData.sort((a, b) => {
    let indexA = selectedData.indexOf(a)
    let indexB = selectedData.indexOf(b)

    if (indexA === -1 && indexB === -1) {
      // Neither a nor b is in referenceArray, maintain original order
      return 0
    } else if (indexA === -1) {
      // a is not in referenceArray, but b is, b comes first
      return 1
    } else if (indexB === -1) {
      // b is not in referenceArray, but a is, a comes first
      return -1
    } else {
      // Both are in referenceArray, sort by their position in referenceArray
      return indexA - indexB
    }
  })
  return sortedData
}

export const getBackendUrl = (isDev) => {
  return isDev
    ? process.env.REACT_APP_BACKEND_URL_DEV
    : process.env.REACT_APP_BACKEND_URL_PROD
}
