import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from 'monday-ui-react-core'

export const MigratedFolders = ({ folders, context }) => {
  const columns = [
    {
      id: 'name',
      loadingStateType: 'medium-text',
      title: 'Folder name',
      width: 450,
    },
  ]
  return (
    <div>
      <h1>Already migrated folders</h1>
      <div
        style={{
          height: '300px',
          width: '450px',
          margin: 'auto',
        }}
      >
        {folders && (
          <Table
            className={
              context?.data?.theme !== 'light'
                ? 'table_comp'
                : 'table_comp_white'
            }
            size={Table.sizes.LARGE}
            columns={columns}
          >
            <TableHeader>
              <TableHeaderCell title="Folder name" />
            </TableHeader>
            <TableBody>
              {folders.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                        textAlign: 'left',
                      }}
                    >
                      <div>{item.folderName}</div>
                      <span style={{ color: 'gray' }}>
                        {item.workspaceName}
                      </span>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </div>
    </div>
  )
}
