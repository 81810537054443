import './Spinner.css'
const Spinner = ({ context }) => {
  return (
    <>
      <span className="loader"></span>
    </>
  )
}

export default Spinner
