import { useEffect, useState } from 'react'
import './App.css'
import 'monday-ui-react-core/tokens'
import mondaySdk from 'monday-sdk-js'
import Header from './components/Common/Header/Header'
import MainScreen from './screens/MainScreen/MainScreen'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

function App() {
  const [theme, setTheme] = useState('light')
  const [context, setContext] = useState(null)
  const monday = mondaySdk()
  monday.setApiVersion('2023-10')

  useEffect(() => {
    getContext()
  }, [])

  const getContext = async () => {
    const getContext = await monday.get('context')
    if (getContext.data) {
      setTheme(getContext.data.theme)
      setContext(getContext)
    }
  }

  return (
    <div className={`App layout-${theme}`}>
      <Header />
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme={theme === 'light' ? 'light' : 'dark'}
      />
      {monday && <MainScreen monday={monday} context={context} />}
    </div>
  )
}

export default App
