import React, { useEffect, useState } from 'react'
import './DatabaseSync.css'
import { mondayStorageKeyValues } from '../../util/constants'
import mondaySdk from 'monday-sdk-js'
import RequiredSign from '../RequiredSign/RequiredSign'

import {
  saveToSecureStorage,
  testDBConnection,
} from '../../util/backend.service'
import { RotatingLines } from 'react-loader-spinner'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Button from '../Common/Button/Button'
import Spinner from '../Common/Spinner/Spinner'

const DatabaseSync = ({ isDev, handleFormValid }) => {
  const [selectedDBType, setSelectedDBType] = useState(
    mondayStorageKeyValues.pgDatabase
  )
  const [pgCredentials, setPgCredentials] = useState({})
  const [submitClicked, setSubmitClicked] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [testConnectionLoading, setTestConnectionLoading] = useState(false)

  const monday = mondaySdk()

  useEffect(() => {
    getInitialValues()
  }, [])

  const getInitialValues = async () => {
    setIsLoading(true)
    const syncValueResp = await monday.storage.getItem(
      mondayStorageKeyValues.syncEnabled
    )
    const dbTypeResp = await monday.storage.getItem(
      mondayStorageKeyValues.DBType
    )

    if (!syncValueResp.data || !dbTypeResp.data) {
      return
    }
    const dbType = dbTypeResp.data.value
    if (dbType) {
      setSelectedDBType(dbType)
    }
    if (dbType === mondayStorageKeyValues.pgDatabase) {
      const credentials = await monday.storage.getItem(
        mondayStorageKeyValues.pgDatabase
      )
      setPgCredentials((prevState) => {
        const newValue = JSON.parse(credentials.data.value)
        return newValue
      })
    }
    setIsLoading(false)
  }

  const showToast = (message, type) => {
    toast(message, { type: type })
  }

  const onSelectDatabaseType = (e) => {
    setSelectedDBType(e.target.value)
  }

  const onSavePG = async () => {
    if (!validateFormPG()) {
      setSubmitClicked(true)
      return
    }
    setIsLoading(true)
    const respSetCredentials = await monday.storage.setItem(
      mondayStorageKeyValues.pgDatabase,
      JSON.stringify(pgCredentials),
      { shared: true }
    )
    const respSetDBType = await monday.storage.setItem(
      mondayStorageKeyValues.DBType,
      selectedDBType,
      { shared: true }
    )
    await saveToSecureStorage(monday, isDev, {
      credentials: JSON.stringify(pgCredentials),
      dbType: selectedDBType,
    })
    if (!respSetCredentials.data?.success) {
      showToast('An error occurred while saving Database Credentials!', 'error')
    }
    if (!respSetDBType.data?.success) {
      showToast('An error occurred while saving Database Type!', 'error')
    }
    setIsLoading(false)
  }

  const onSubmitPG = async () => {
    if (!validateFormPG()) {
      setSubmitClicked(true)
      return
    }
    setTestConnectionLoading(true)
    const testConnection = await testDBConnection(
      monday,
      'PG',
      pgCredentials,
      isDev
    )
    if (!testConnection) {
      showToast('Test connection FAILED!', 'error')
      setTestConnectionLoading(false)
      return
    } else {
      showToast('Test connection successfull!', 'success')
      setTestConnectionLoading(false)
      return
    }
  }

  const handleInputChangePG = (e, propName) => {
    setPgCredentials((prevState) => {
      return {
        ...prevState,
        [`${propName}`]: e.target.value,
      }
    })
  }

  const validateFormPG = () => {
    if (
      !pgCredentials.user ||
      !pgCredentials.password ||
      !pgCredentials.host ||
      !pgCredentials.port ||
      !pgCredentials.database
    ) {
      handleFormValid(false)
      return false
    }
    handleFormValid(true)
    return true
  }

  const renderPGDBForm = () => {
    return (
      <div>
        <form className="form-style">
          <div className="form-inline">
            <div className="form-column">
              <label className="label-style">
                User <RequiredSign />
              </label>
              <input
                required
                defaultValue={pgCredentials.user}
                onChange={(e) => handleInputChangePG(e, 'user')}
                placeholder="Enter user"
                type="text"
              />
            </div>
            <div className="form-column">
              <label className="label-style">
                Password <RequiredSign />
              </label>
              <input
                required
                defaultValue={pgCredentials.password}
                onChange={(e) => handleInputChangePG(e, 'password')}
                placeholder="Enter password"
                type="password"
              />
            </div>
          </div>
          <div className="form-inline">
            <div className="form-column input-host">
              <label className="label-style">
                Host <RequiredSign />
              </label>
              <input
                required
                defaultValue={pgCredentials.host}
                onChange={(e) => handleInputChangePG(e, 'host')}
                placeholder="Enter host name"
                type="text"
              />
            </div>
            <div className="form-column input-port">
              <label className="label-style">
                Port <RequiredSign />
              </label>
              <input
                required
                defaultValue={pgCredentials.port}
                onChange={(e) => handleInputChangePG(e, 'port')}
                placeholder="Port"
                type="text"
              />
            </div>
          </div>
          <div className="form-column">
            <label className="label-style">
              Database <RequiredSign />
            </label>
            <input
              required
              defaultValue={pgCredentials.database}
              onChange={(e) => handleInputChangePG(e, 'database')}
              placeholder="Enter database name"
              type="text"
            />
          </div>
        </form>
        {!validateFormPG() && submitClicked && (
          <div className="validation-message">All field are required!</div>
        )}
        <div className="db-save-btns">
          <button
            className="submit-style"
            type="submit"
            onClick={onSubmitPG}
            placeholder="submit"
          >
            {testConnectionLoading ? (
              <div className="btn-loading">
                <RotatingLines width="12px" strokeColor="gray" />
              </div>
            ) : (
              'Test Connection...'
            )}
          </button>
          <Button title={'Save Database settings'} onClick={onSavePG} />
        </div>
      </div>
    )
  }

  return (
    <div className="db-body">
      <div className="divider"></div>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="db-sync-content">
          <div className="sync-enabled">
            <h1>Connect to your database</h1>
            <label style={{ textAlign: 'start' }}>Select database type</label>
            <select
              className="dropdown-width"
              defaultValue={selectedDBType}
              onChange={onSelectDatabaseType}
            >
              <option value={mondayStorageKeyValues.pgDatabase}>
                PostgreSQL database
              </option>
            </select>
            {selectedDBType === mondayStorageKeyValues.pgDatabase && (
              <div>{renderPGDBForm()}</div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default DatabaseSync
