export const getAccountName = async (monday) => {
  try {
    const query = 'query{ account { name }}'
    const resp = await monday.api(query)
    if (resp.data.account && resp.data.account.name) {
      return resp.data.account.name
    }
  } catch (err) {
    console.error(err)
    return []
  }
}

export const getWorkspaces = async (monday) => {
  try {
    const data = []
    let index = 0
    let finished = false

    while (!finished) {
      console.log('Getting workspaces...', index)
      index++
      const query = `query{
        workspaces(limit: 20, page: ${index}){
          id
          name
        }
        }
  `

      const resp = await monday.api(query)
      if (resp.data.workspaces.length === 0) {
        finished = true
      } else {
        resp.data.workspaces.forEach((item) => data.push(item))
      }
    }
    return data
  } catch (err) {
    console.error(err)
    return []
  }
}

export const getFolderWorkspace = async (monday, folderIds) => {
  try {
    const data = []
    let index = 0
    let finished = false

    while (!finished) {
      console.log('Getting folder workspace...', index)
      index++
      const query = `query($folderIds: [ID!]){
        folders(ids: $folderIds, limit: 20, page: ${index}){
          id
          workspace{
            id
            name
            }
          }
        }
  `

      const variables = { folderIds }
      const resp = await monday.api(query, { variables })
      if (resp.data.folders.length === 0) {
        finished = true
      } else {
        resp.data.folders.forEach((item) =>
          data.push({ folderId: item.id, workspace: item.workspace })
        )
      }
    }
    return data
  } catch (err) {
    console.error(err)
    return []
  }
}

export const getFolders = async (workspaceId, monday) => {
  try {
    const data = []
    let index = 0
    let finished = false

    while (!finished) {
      console.log('Getting folders...', index)
      index++
      const query = `query($workspaceId: [ID!]){
        folders(limit: 20, workspace_ids: $workspaceId, page: ${index}){
          id
          name
          workspace{
            id
            name
          }
        }
        }
  `

      const variables = { workspaceId }
      const resp = await monday.api(query, { variables })
      if (resp.data.folders.length === 0) {
        finished = true
      } else {
        resp.data.folders.forEach((item) => data.push(item))
      }
    }
    return data
  } catch (err) {
    console.error(err)
    return []
  }
}

// export const getColumns = async (workspaceId, monday) => {
//   try {
//     const data = []
//     let index = 0
//     let finished = false

//     while (!finished) {
//       console.log('Getting folders...', index)
//       index++
//       const query = `query($workspaceId: [ID!]){
//         folders(limit: 20, workspace_ids: $workspaceId, page: ${index}){
//           id
//           name
//           workspace{
//             id
//             name
//           }
//         }
//         }
//   `

//       const variables = { workspaceId }
//       const resp = await monday.api(query, { variables })
//       if (resp.data.folders.length === 0) {
//         finished = true
//       } else {
//         resp.data.folders.forEach((item) => data.push(item))
//       }
//     }
//     return data
//   } catch (err) {
//     console.error(err)
//     return []
//   }
// }

// export const getBoardColumnNamesAndTypes = async (boardId, monday) => {
//   try {
//     console.log('Getting board column names and types...')

//     const query = `query($boardId: [ID!]){
//             boards(ids: $boardId){
//               columns{
//                 title
//                 type
//               }
//             }
//           }
//       `
//     const variables = { boardId }
//     const response = await monday.api(query, { variables })

//     if (
//       !response.data ||
//       response.data.boards.length === 0 ||
//       response.data.boards[0].columns?.length === 0
//     ) {
//       console.log('No columns in board')
//       return []
//     }
//     return response?.data?.boards[0].columns
//   } catch (err) {
//     console.error(err)
//     return []
//   }
// }

// export const getBoardsFromFolder = async (folderId, monday) => {
//   try {
//     console.log('Getting boards from folder...')
//     const query = `query($folderId: [ID!]){
//         folders(ids: $folderId){
//             id
//             name
//             children{
//               id
//               name
//               state
//             }
//         }
//       }
//   `

//     const variables = { folderId }
//     const resp = await monday.api(query, { variables })
//     if (resp.data.folders.length > 0) {
//       return resp.data.folders[0]
//     } else {
//       return []
//     }
//   } catch (err) {
//     console.error(err)
//     return []
//   }
// }

export const getBoardFromWorkspace = async (workspaceId, monday) => {
  try {
    const data = []
    let index = 0
    let finished = false

    while (!finished) {
      console.log('Getting boards...', index)
      index++
      const query = `query($workspaceId: [ID!]){
        boards(limit: 20, state:active, workspace_ids: $workspaceId, page: ${index}){
          id
          name
          type
        }
        }
  `

      const variables = { workspaceId }
      const resp = await monday.api(query, { variables })
      if (resp.data.boards.length === 0) {
        finished = true
      } else {
        resp.data.boards.forEach((board) => data.push(board))
      }
    }
    const filteredBoards = data.filter(
      (board) => board.type !== 'sub_items_board'
    )
    return filteredBoards
  } catch (err) {
    console.error(err)
    return []
  }
}

export const saveValueIntoMondayStorage = async (key, value, monday) => {
  try {
    const resp = await monday.storage.setItem(key, value.toString(), {
      shared: true,
    })
    if (resp.data.success) {
      console.log(resp, 'setting')
      return resp.data
    } else {
      console.log(resp, 'error')
      return null
    }
  } catch (error) {
    console.log(error)
    return null
  }
}

export const getValueFromMondayStorage = async (key, monday) => {
  try {
    const resp = await monday.storage.getItem(key)
    if (resp.data.success) {
      console.log(resp)
      return resp.data.value
    } else {
      console.log(resp, 'error')
      return null
    }
  } catch (error) {
    console.log(error)
    return null
  }
}

// export const createWebhook = async (boardId, webhook, monday) => {
//   console.log('Creating board webhook', boardId, webhook)

//   try {
//     const mutation = `mutation ($boardId: ID!, $webhook: WebhookEventType!, $url: String!) {
//       create_webhook(board_id: $boardId, event: $webhook, url: $url) {
//         id
//       }
//     }
//       `
//     const url = `https://7494-178-254-186-101.ngrok-free.app/exporter/webhook`
//     const variables = { boardId, webhook, url }
//     const response = await monday.api(mutation, { variables })

//     if (!response.data) {
//       console.error(response)
//       throw new Error('Missing response data in creating board webhooks.')
//     }
//     console.log(`Created webhook ${webhook} for board ${boardId}.`)
//     return response.data
//   } catch (err) {
//     console.error(err)
//     throw new Error(err)
//   }
// }
