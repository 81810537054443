import React, { useEffect, useMemo, useState } from 'react'
import './WorkspacesAndBoards.css'
import { getBoardFromWorkspace, getFolders } from '../../util/monday.service'
import { sortData } from '../../util/util'
import {
  exportToDatabase,
  getMigratedBoardsFromDatabase,
} from '../../util/backend.service'
import {
  Button,
  Checkbox,
  Search,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
  Tooltip,
} from 'monday-ui-react-core'
import Spinner from '../Common/Spinner/Spinner'

const WorkspacesAndBoards = ({
  monday,
  context,
  selectedWorkspace,
  setSelectedWorkspace,
  setAppLoading,
  isDev,
  isFormValid,
}) => {
  const [folders, setFolders] = useState([])
  const [boards, setBoards] = useState([])
  const [selectedFolders, setSelectedFolders] = useState([])
  const [selectedBoards, setSelectedBoards] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (!selectedWorkspace) {
      setBoards([])
      setFolders([])
      return
    }
    const fetchFolders = async () => {
      const resp = await getFolders(selectedWorkspace.value, monday)
      if (resp) {
        setFolders(resp)
      }
    }

    const getBoards = async () => {
      setIsLoading(true)
      const boards = await getBoardFromWorkspace(
        selectedWorkspace.value,
        monday
      )
      const migratedBoards = await getMigratedBoardsFromDatabase(monday, isDev)
      migratedBoards?.forEach((board) => {
        const found = boards.find((f) => parseInt(f.id) === board)
        if (found) {
          found.migrated = true
        }
      })
      setBoards(boards)
      await fetchFolders()
      setIsLoading(false)
    }
    if (selectedWorkspace && monday) {
      setBoards([])
      setFolders([])
      setSelectedBoards([])
      setSelectedFolders([])
      getBoards()
    }
  }, [selectedWorkspace, monday])

  useEffect(() => {
    if (selectedBoards.length > 0 && boards.length > 0) {
      const sortedData = sortData(selectedBoards, boards)
      setBoards(sortedData)
    }
  }, [selectedBoards])

  useEffect(() => {
    if (selectedFolders.length > 0 && folders.length > 0) {
      const sortedData = sortData(selectedFolders, folders)
      setFolders(sortedData)
    }
  }, [selectedFolders])

  const SelectWorkspaceComponent = () => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '25px 0',
        }}
      >
        <h2>Select Workspace you want to work with</h2>
      </div>
    )
  }

  const selectItem = (dataItem, type) => {
    //Select and deselect function
    if (type === 'boards') {
      if (!selectedBoards.find((sb) => sb.id === dataItem.id)) {
        setSelectedBoards((prevState) => [...prevState, dataItem])
      } else {
        setSelectedBoards((prevState) =>
          prevState.filter((ps) => ps.id !== dataItem.id)
        )
      }
    } else {
      if (!selectedFolders.find((sf) => sf.id === dataItem.id)) {
        setSelectedFolders((prevState) => [...prevState, dataItem])
      } else {
        setSelectedFolders((prevState) =>
          prevState.filter((ps) => ps.id !== dataItem.id)
        )
      }
    }
  }

  const WorkspaceTableComponent = ({ data, type }) => {
    const [filteredData, setFilteredData] = useState([...data])
    // Memoize the columns array since it does not change
    const columns = useMemo(
      () => [
        {
          id: 'name',
          loadingStateType: 'medium-text',
          title: type === 'boards' ? 'Board name' : 'Folder name',
          width: 450,
        },
      ],
      [type]
    )

    return (
      <div style={{ height: '300px', width: '450px' }}>
        {isLoading ? (
          <Spinner />
        ) : (
          <Table
            className={
              context?.data?.theme !== 'light'
                ? 'table_comp'
                : 'table_comp_white'
            }
            dataState={{
              isLoading: isLoading,
            }}
            size={Table.sizes.LARGE}
            columns={columns}
          >
            <TableHeader className="customTableHeaderCell">
              <TableHeaderCell
                className="customTableHeaderCell"
                title={
                  <div
                    style={{
                      display: 'grid',
                      gridTemplateColumns: '1fr 1fr',
                      alignItems: 'center',
                    }}
                  >
                    <div style={{ fontWeight: 'bold' }}>
                      {type === 'boards' ? 'Board name' : 'Folder name'}
                    </div>
                    <Search
                      onChange={(value) => {
                        if (type === 'boards') {
                          const filteredBoards = boards.filter((board) =>
                            board.name
                              .toLowerCase()
                              .includes(value.toLowerCase())
                          )
                          if (filteredBoards.length > 0) {
                            setFilteredData(filteredBoards)
                          }
                        } else {
                          const filteredFolders = folders.filter((folder) =>
                            folder.name
                              .toLowerCase()
                              .includes(value.toLowerCase())
                          )
                          if (filteredFolders.length > 0) {
                            setFilteredData(filteredFolders)
                          }
                        }
                      }}
                      placeholder={
                        type === 'boards'
                          ? 'Search by Board Name'
                          : 'Search by Folder Name'
                      }
                    />
                  </div>
                }
              />
            </TableHeader>
            <TableBody>
              {filteredData.map((record) => (
                <TableRow key={record.id}>
                  <TableCell>
                    {
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        {record.migrated ? (
                          <Tooltip
                            zIndex={9999}
                            title="Migrated"
                            content={`  To remove an already Migrated Board, delete all of its tables in
                the database, and delete the record of this board in the
                "Migrated Boards" table in the Database.`}
                          >
                            <Checkbox
                              disabled={record.migrated}
                              checked={
                                selectedBoards.some(
                                  (sb) => sb.id === record.id
                                ) ||
                                selectedFolders.some(
                                  (sf) => sf.id === record.id
                                )
                              }
                              label={`${record?.name} ${record.migrated ? '- MIGRATED' : ''}`}
                              onChange={() => {
                                selectItem(record, type)
                              }}
                            />
                          </Tooltip>
                        ) : (
                          <Checkbox
                            disabled={record.migrated}
                            checked={
                              selectedBoards.some(
                                (sb) => sb.id === record.id
                              ) ||
                              selectedFolders.some((sf) => sf.id === record.id)
                            }
                            label={`${record?.name} ${record.migrated ? '- MIGRATED' : ''}`}
                            onChange={() => {
                              selectItem(record, type)
                            }}
                          />
                        )}
                      </div>
                    }
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </div>
    )
  }

  const exportData = async () => {
    const selectedFolderIds = selectedFolders.map((sf) => sf.id)
    const selectedBoardIds = selectedBoards.map((sb) => sb.id)
    await exportToDatabase(selectedFolderIds, selectedBoardIds, monday, isDev)
  }

  return (
    <div className="workspacesAndBoardsBody">
      {!selectedWorkspace ? (
        <SelectWorkspaceComponent />
      ) : (
        <div
          className="workspacesAndBoardsBody"
          style={{
            alignItems: 'center',
            marginTop: '25px',
            flexDirection: 'column',
          }}
        >
          <h1>Select Boards and Folders that you want to Migrate</h1>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '50px',
              marginTop: '25px',
            }}
          >
            {/* <div>
              <WorkspaceTableComponent data={boards} type="boards" />
            </div> */}
            <div>
              <WorkspaceTableComponent data={folders} type="folders" />
              <div style={{ marginTop: '25px', float: 'right' }}>
                <Button
                  disabled={
                    (selectedBoards.length === 0 &&
                      selectedFolders.length === 0) ||
                    !isFormValid
                  }
                  onClick={async () => {
                    setAppLoading(true)
                    await exportData()
                    setAppLoading(false)
                    setSelectedWorkspace(null)
                  }}
                  color={Button.colors.PRIMARY}
                >
                  Migrate to Database
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default WorkspacesAndBoards
